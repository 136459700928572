import { IUser } from '@/_utils/types';
import { InjectionKey } from 'vue'
import { createStore, Store, ActionTree, ActionContext, MutationTree, GetterTree } from 'vuex'
type Account = Pick<IUser, "account">|null
interface IState {
  account?: Account
}
const state: IState = {
  account: null
}

export type State = typeof state
export enum MutationTypes {
  SET_ACCOUNT = 'SET_ACCOUNT',
}
type Mutations<S = State> = {
  [MutationTypes.SET_ACCOUNT](state: S, payload: Account): void
}

export type Getters = {
  account(state: State): Account|undefined
}
export const key: InjectionKey<Store<State>> = Symbol()

const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_ACCOUNT](state: State, account: Account) {
    console.log('mutate', account)
    state.account = account
  },
}
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>
enum ActionTypes {
  GET_ACCOUNT = 'GET_ACCOUNT',
}
interface Actions {
  [ActionTypes.GET_ACCOUNT](
    { commit }: AugmentedActionContext,
    payload: Account
  ): Promise<Account>
}
const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.GET_ACCOUNT]({ commit }, data: Account) {
    commit(MutationTypes.SET_ACCOUNT, data)
    return Promise.resolve(data)
  }
}
const getters: GetterTree<State, State> & Getters = {
  account: state => state.account
}
export const store = createStore<State>({
  state,
  getters,
  mutations,
  actions
})
// export default createStore({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });
