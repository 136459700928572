import { reactive, toRefs, watch, Ref, onMounted, computed } from 'vue'
import { useRequest } from 'vue-request';
import { IAccount, IAuthToken, IFeature, IUser } from "@/_utils/types"
import { accountService, appService } from "@/_services"

export const AUTH_KEY = 'satUserManagement'
export const AUTH_TOKEN = 'satToken'
export const FEATURE_TOKENS = 'satTokens'


interface AuthState {
    authenticating: boolean;
    user?: IUser;
    error?: Error;
    token: string|null
    account: IAccount|null;
    feature: IFeature|null;
    featureTokens: IFeatureTokens|null;
}
interface IFeatureTokens {
    bigData: string|null
    academy: string|null
    eLerning: string|null
}

const state = reactive<AuthState>({
    authenticating: false,
    // user: undefined,
    error: undefined,
    token: null,
    account: null,
    feature: null,
    featureTokens: null
})

interface IUseAuth {
    // setUser: (user: IUser|undefined) => void
    setToken: (token: IAuthToken|null) => void
    setAccount: (account: IAccount|null) => void
    setFeature: (feature: IFeature|null) => void
    setFeatureTokens: (tokens: IFeatureTokens|null) => void
    logout: () => void
    authenticating: Ref<boolean>
    user?: Ref<IUser|undefined>
    error?: Ref<Error|undefined>
    token: Ref<string|null>
    account: Ref<IAccount|null>
    feature: Ref<IFeature|null>
    featureTokens: Ref<IFeatureTokens|null>
}
export const useAuth = (): IUseAuth => {
    
    // Read access token from local storage?
    const checkAuth = () => {
        const accessToken = window.localStorage.getItem(AUTH_KEY)
        state.token = accessToken
        const { loading, data: result, error } = useRequest(accountService.getRolePermission, { ready: computed(() => (state.token !== null && state.account == null)) })
        watch([ loading ], () => {
            console.log('loading get role', loading.value)
            if ( error.value ) {
                console.log('[ERROR] on getRole', error.value)
                window.localStorage.removeItem(AUTH_KEY)
                window.localStorage.removeItem(FEATURE_TOKENS)
            } else if ( result.value ) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { account, account_role } = result.value.data as any // "any" cause api return account_role, should be account_roles inside account
                state.account = {
                    ...account,
                    account_roles: account_role
                }
                state.featureTokens = JSON.parse(localStorage.getItem(FEATURE_TOKENS) ?? 'null')
                console.log('[RESULT] on getRole', state.featureTokens)
            }  
        })
        const { data: feature } = useRequest(appService.getFeatures, { 
            ready: computed(() => (state.token !== null && state.feature == null)),
            onSuccess: () => {
                state.feature = feature.value?.data ?? null
            }
        })
    }

    const setToken = (token: IAuthToken|null): void => {
        if ( token ) {
            window.localStorage.setItem(AUTH_KEY, token.access_token)
        } else {
            window.localStorage.removeItem(AUTH_KEY)
        }
        state.token = token?.access_token ?? null
    }

    const setFeatureTokens = (tokens: IFeatureTokens|null) => {
        window.localStorage.setItem(FEATURE_TOKENS, JSON.stringify(tokens))
        state.featureTokens = tokens
    }

    const setAccount = (account: IAccount|null): void => {
        state.account = account
    }

    const setFeature = (feature: IFeature|null): void => {
        state.feature = feature
    }

    const logout = (): Promise<void> => {
        window.localStorage.removeItem(AUTH_KEY)
        state.user = undefined
        state.account = null
        state.token = null
        state.feature = null
        return Promise.resolve()
    }

    onMounted(checkAuth)

    return {
        setToken,
        setAccount,
        setFeature,
        setFeatureTokens,
        logout,
        ...toRefs(state),
    }

}

export default { useAuth }