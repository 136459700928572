
import { ExclamationCircleOutlined, CheckCircleFilled } from "@ant-design/icons-vue";
import { defineComponent, watch, ref, reactive, toRefs } from 'vue'
import { onMounted } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import { useAuth } from '@/_modules/auth';
import { IAlertModal } from '@/_utils/types';
import AlertModal from "@/components/AlertModal.vue";
import { authService } from "@/_services";
import { useStore } from 'vuex'
import { key, MutationTypes } from '../store'

export default defineComponent({
  components: {
    AlertModal,
    ExclamationCircleOutlined,
    CheckCircleFilled
  },
  setup() {
    const { logout } = useAuth();
    const store = useStore(key)
    const router = useRouter();
    const route = useRoute()
    const alertState = reactive<IAlertModal>({
      visible: false,
      message: "",
      type: "danger"
    })
    const onCloseAlertModal = () => {
      alertState.visible = false
      router.replace({name: 'home'})
    }
    const token = ref(`${route.query?.token ?? ''}`)
    console.log('route', route.path, token.value)
    
    watch(() => route.query, (value, prevValue) => {
      console.log('watchAccount', value, prevValue)
    }, { immediate: true })

    onMounted(() => {
      if(!token.value){
        alertState.message = 'Token not found!'
        alertState.visible = true
        alertState.type = 'danger'
        return
      }
      logout()
      let promise;
      if(route.name === 'reset-password'){
        promise = authService.verifyResetPasswordToken(token.value)
      } else {
        promise = authService.verifyEmailConfirmationToken(token.value)
      }
      promise.then(result => {
        const { success, data, error_code, message } = result as any
        if(!success) {
          alertState.message = message
          alertState.visible = true
          alertState.type = 'danger'
          return
        }
        if(!data) {
          alertState.message = 'Token not found!'
          alertState.visible = true
          alertState.type = 'danger'
          return
        }
        const { account } = data
        console.log(data.account)
        store.commit(MutationTypes.SET_ACCOUNT, account)
        router.replace({ name: "create-password" })
      }, error => {
        console.error(error)
        alertState.message = error.message
        alertState.visible = true
        alertState.type = 'danger'
      })
    })
    
    return {
      onCloseAlertModal,
      ...toRefs(alertState)
    }
  }
})
